
  import {
    defineComponent,
    Ref,
    ref,
    onMounted,
  watch,
  } from "vue";
  import { ErrorMessage, Field, Form } from "vee-validate";
  import ModalUsersIpiranga from "./components/ModalUsersIpiranga.vue";
  import Title from "@/components/Title.vue"
  import Button from "@/components/Button.vue"
  import { useRouter,useRoute, LocationQueryValue } from "vue-router";
  import { Modal } from "bootstrap";
  import Pagination from "@/components/Pagination.vue";
  import Api from "@/services/Api";
  import useEmitter from "@/composables/Emmiter";
  
  interface Input {
    nome: string,
    email: string,
    regiao: string,
    cargo: string
  }
  
  
  export default defineComponent({
    name: "UsuariosIpiranga",
  
    components: {
      ModalUsersIpiranga,
      Title,
      Button,
      Pagination,
    },
  
    setup() {
      const router = useRouter();
  
      const route = useRoute();
      const emitter = useEmitter();
  
      const page: Ref<number> = ref(1)
      const perPage: Ref<number> = ref(10);

      const limite: Ref<number> = ref(20);
  
      const usersArray: Ref<any[]> = ref([]);
      const totalUsers: Ref<number> = ref(0);

  
      const usuarioModal: Ref<any> = ref({});
      const modalUsuario: Ref<any> = ref();

      const loadUsers: Ref<boolean> = ref(false);
  
      //refs recebidos do FilterUser
      const input: Ref<Input> = ref({
        nome: "",
        email: "",
        regiao: "",
        cargo: ""
      });
  
      const idLoja: Ref<LocationQueryValue[] | string | null> = ref(null)
      const lojaFixada: Ref<any> = ref(null)
      const fastFind: Ref<string> = ref('');
  
  
      function openModal(usuario?) {
        usuarioModal.value = usuario || {}
        modalUsuario.value.show()
      }
  
      async function fechaModal() {
        modalUsuario.value.hide()
      }
  
  
      async function getUsuarios(){
        //ADICIONAR LOADING AO PROCURAR USUARIOS
        const { nome, email, regiao, cargo} = input.value
        loadUsers.value = true
        try{
          const { data, error, message } = await Api.get("buscaUsuarioIpirangaPaginado", {
            page: page.value,
            perPage: perPage.value,
            nome,
            email,
            regiao,
            cargo,
            search: fastFind.value
          })
          usersArray.value = data.users
          totalUsers.value = data.total

        }catch(error){
          console.log("error", error)
        }finally{
          loadUsers.value = false
        }
      }
  
  
      emitter.on("Filter-UsuariosIpiranga", (valor: any) => {
        if(valor) input.value = valor;
        page.value = 1
        getUsuarios()
      })
  
      emitter.on("enviaUsuarioIpirangaAtualizado", (valor: any) => {
  
        const index = usersArray.value.findIndex(({id}) => id === valor.usuario.id)
  
        if(index !== -1) usersArray.value[index] = { ...valor.usuario }
        else usersArray.value.unshift({ ...valor.usuario })
  
      })
  
      emitter.on("removeUsuarioLista", (valor: any) => {
  
        const index = usersArray.value.findIndex(({id}) => id === valor.usuario.id)
  
        if(index !== -1) usersArray.value.splice(index,1)
  
      })


    // function getLocalStorage(){
    //     lojaFixada.value = JSON.parse(localStorage.getItem('lojaFixada') as any)      
    //     if(lojaFixada.value){
    //       idLoja.value = lojaFixada.value && lojaFixada.value.id
    //     }
    //   }
    
  
      
      
      function buscar(){
        //função para buscar usuarios no filtro rapido utilizando keyup
        if(fastFind.value.length > 2 || !fastFind.value){
            page.value = 1
            getUsuarios()
          }
       }
  
    //Ao modificar o parametro da rota faz nova chamada na api
    //  watch(
    //   () => route.query.idLoja,
    //   () => {
    //     input.value = {
    //     cargo: "",
    //     data: ""
    //   }
  
    //     if(route.query.idLoja){
    //       idLoja.value = route.query.idLoja
    //       buscaUsuario(pagina.value, true)
    //     }else{
    //       idLoja.value = null
    //       buscaUsuario(pagina.value, true)
    //     }
    //   }
  
    //  )
  
    //  async function pegarLojaId() {
    //     await emitter.emit("idLojaUser", {
    //       idLoja: idLoja.value,
    //     });
    //   }

    function handlePageChange(pageParam) {
      page.value = pageParam;
      getUsuarios();
    }
  
      onMounted(async () => {
        modalUsuario.value = new Modal(document.querySelector(`#ModalUsersIpiranga`));
        // idLoja.value = route.query.idLoja || null
        // getLocalStorage();
        // await buscaUsuario(pagina.value)
        // // scroll infinito
        // const observer = new IntersectionObserver(entries => {
        //   if (entries[0].isIntersecting) buscaUsuario(pagina.value = pagina.value + 1);
        // }).observe(document.querySelector("#load") as any);
        getUsuarios()
  
      })
  
      
  
      return {
        usersArray,
        limite,
        history,
        openModal,
        fechaModal,
        router,
        loadUsers,
        getUsuarios,
        usuarioModal,
        idLoja,
        lojaFixada,
        fastFind,
        buscar,
        route,
        handlePageChange,
        totalUsers
      };
    },
  });
  