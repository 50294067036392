
import Api from "@/services/Api";
import { defineComponent, Ref, ref, onMounted, watch } from "vue";
import type { PropType } from "vue";
import useAlert from '@/composables/Alert'
import useEmitter from "@/composables/Emmiter";
import Button from "@/components/Button.vue"



export default defineComponent({
  name: "ModalUsersIpiranga",
  components: {
    Button,
  },
  props: {
    usuario: Object as PropType<any>,
    fechaModal: Function,
    abreModal: Function,
  },

  setup(props) {

    const { showTimeAlert } = useAlert()
    const emitter = useEmitter()

    const user: Ref<any> = ref({});
    const arrayLojas: Ref<any[]> = ref([]);
    const usuarioLojas: Ref<any> = ref([]);

    const loadSave: Ref<boolean> = ref(false);
    const loadExcluir: Ref<boolean> = ref(false);
    const loadRemoveUsuario: Ref<boolean> = ref(false);
    const loadResetarSenha: Ref<boolean> = ref(false);
    const dialogVisible: Ref<boolean> = ref(false);

    const clikouResetarSenha: Ref<boolean> = ref(false);
    const novaSenha: Ref<string> = ref('indicar');


    const adicionarUsuarioEmNovaLoja: Ref<boolean> = ref(false)

    const arrayCargos: Ref<any[]> = ref([]);
    const idLoja: Ref<any> = ref(null);


    function closeModal() {

      props.fechaModal && props.fechaModal()

    }

    function abreModal() {

      user.value = { ...props.usuario }
      clikouResetarSenha.value = false
    }

    function enviaUsuarioAtualizado(usuarioParam) {
      emitter.emit('enviaUsuarioIpirangaAtualizado', { usuario: usuarioParam })
    }

    function removeUsuarioLista(usuarioParam) {
      emitter.emit('removeUsuarioLista', { usuario: usuarioParam })
    }

    async function deletarUsuario(id_user: number) {

      loadExcluir.value = true

      const { data, message } = await Api.delete("deletaUsuarioIpiranga", { id_user })

      loadExcluir.value = false

      if (!data) {
        dialogVisible.value = false;
        closeModal()
        return showTimeAlert(message, "error")
      }

      removeUsuarioLista(data)

      dialogVisible.value = false;
      closeModal()

      return showTimeAlert('Usuário deletado com sucesso!', "success")

    }

    async function salvaUsuario(id_user: number) {

      loadSave.value = true
      const { nome, email, regiao, cargo } = user.value

      const { data, message } = await Api.post("createOrUpdateUser", { nome, email, regiao, cargo, id_user });

      loadSave.value = false

      if (!data) return showTimeAlert(message, "error");

      //Atualizar lista de usuarios
      enviaUsuarioAtualizado(data)


      closeModal()

      return showTimeAlert('Usuário salvo com sucesso!', "success")

    }

    // abrindo modal com usuario
    watch(() => props.usuario, async () => { abreModal() }, { deep: true })

    return {
      user,
      closeModal,
      deletarUsuario,
      salvaUsuario,
      loadSave,
      loadRemoveUsuario,
      arrayCargos,
      loadExcluir,
      arrayLojas,
      adicionarUsuarioEmNovaLoja,
      clikouResetarSenha,
      novaSenha,
      loadResetarSenha,
      usuarioLojas,
      idLoja,
      dialogVisible
    };
  },
});
